.border-gradient {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  gap: 12px;
  border: 3px solid transparent;
}

.border-gradient:hover {
  border-image: linear-gradient(to right, #00dcb4, #e71d7e) 10;
}

.border-gradient-always {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  gap: 12px;
  border: 3px solid transparent;
  border-image: linear-gradient(to right, #00dcb4, #e71d7e) 10;
  width: 650px;
}
